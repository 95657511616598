import React from 'react'
import styled from 'styled-components'
import { breakpoints, spacing, font } from '../utils/constants'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import ContentContainer from '../components/ContentContainer'
import TwitchStream from '../components/TwitchStream'

const PerformanceTitle = styled.h1`
  width: 100%;
  margin: ${spacing.lg} 0 0 0;
  text-align: center;
`

const Byline = styled.h3`
  width: 100%;
  margin: ${spacing.xs} 0 0 0;
  text-align: center;
`

const PerformanceDate = styled.h3`
  width: 100%;
  margin: ${spacing.xs} 0 ${spacing.lg} 0;
  font-family: ${font.light};
  text-align: center;

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: 0;
    margin-bottom: ${spacing.sm};
  }
`

const LivePage = () => (
  <Layout>
    <SEO title="Live" />
    <ContentContainer>
      <PerformanceTitle>Forget My Fate</PerformanceTitle>
      <Byline>A Performance by Lynn Hunter</Byline>
      <PerformanceDate>Dec 07 2019 18:00 UTC-5</PerformanceDate>
    </ContentContainer>
    <ContentContainer>
      <TwitchStream channelName="paradigmwtf" allowFullScreen />
      <TwitchStream channelName="paradigmstreaming" allowFullScreen />
    </ContentContainer>
  </Layout>
)

export default LivePage
