import React from 'react'
import styled from 'styled-components'

import { spacing } from '../../utils/constants'

const StreamContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  margin-bottom: ${spacing.sm};
`

const Stream = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export default function TwitchStream(props) {
  return (
    <StreamContainer>
      <Stream
        src={`https://player.twitch.tv/?channel=${props.channelName}&muted=${props.muted}&autoplay=${props.autoPlay}`}
        allowFullScreen={props.allowFullScreen}
        frameBorder="0"
        scrolling="no"
      />
    </StreamContainer>
  )
}
